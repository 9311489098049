<template>
    <header
        :style="{
            backgroundImage: 'url(' + image + ')',
        }"
    >
        <div id="entireHeader">
            <div id="centerVert">
                <div id="headerCover" class="center">
                    <img :src="logo" @click="goHome" alt="Logo" id="logo" />
                </div>
            </div>
            <div id="h1Box" class="textCenter">
                <h1>Door Opener</h1>
            </div>
        </div>
    </header>
</template>

<script>
import imageLarge from "../../assets/headers/headerlarge.png";
import imageMedium from "../../assets/headers/headermedium.png";
import imageMobile from "../../assets/headers/headermobile.png";

import logo from "../../assets/logo.svg";

export default {
    data: function () {
        return {
            image: imageLarge,
            logo,
            width: 0,
        };
    },
    update() {
        this.onResize();
    },
    beforeMount() {
        window.addEventListener("resize", this.onResize);
        this.onResize();
    },
    methods: {
        onResize() {
            if (window.innerWidth > 1366) {
                this.image = imageLarge;
            } else if (window.innerWidth <= 1366 && window.innerWidth > 1080) {
                this.image = imageMedium;
            } else {
                this.image = imageMobile;
            }
        },
        goHome() {
            this.$store.dispatch("logout");
            window.location.href = "https://www.bkm.lu/";
        },
    },
};
</script>

<style scoped>
header {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 300px;
}
#entireHeader {
    width: 100%;
}
#centerVert {
    padding-top: 100px;
}
#headerCover {
    width: 100%;
    background-color: white;
    border-top: 2px solid #0d202c;
    border-bottom: 2px solid #0d202c;
    text-align: center;
}

#logo {
    height: 143px;
    cursor: pointer;
    margin: 20px 10px;
}

#h1Box {
    padding-top: 150px;
    padding-bottom: 50px;
}

h1 {
    font: normal 3rem "Titillium Web Light";
    color: #0d5379;
    width: 100%;
}

@media all and (max-width: 1366px) and (min-width: 1080px) {
    header {
        min-height: 200px;
    }
    #h1Box {
        padding-top: 100px;
        padding-bottom: 0;
    }
}

@media all and (max-width: 1080px) {
    header {
        min-height: 150px;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
    }
    #entireHeader {
        display: flex;
    }
    #centerVert {
        width: 40%;
        align-self: flex-start;
        padding-top: 0;
    }

    #h1Box {
        padding: 0;
        margin-bottom: 0;
        width: 60%;
        align-self: center;
    }

    h1 {
        font-size: 38px;
        width: 50%;
        margin: auto;
    }

    #headerCover {
        background-color: transparent;
        border: none;
        width: 100%;
    }
    #logo {
        width: 100%;
        padding: 10px 0;
        margin: 0 10px;
    }
}
</style>
