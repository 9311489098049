<template>
    <teleport to="body">
        <div v-if="show" @click="tryClose" class="backdrop"></div>
        <transition name="dialog">
            <dialog open v-if="show">
                <section>
                    <slot></slot>
                </section>
            </dialog>
        </transition>
    </teleport>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        fixed: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ["close"],
    methods: {
        tryClose() {
            if (this.fixed) {
                return;
            }
            this.$emit("close");
        },
    },
};
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
}

dialog {
    position: fixed;
    top: 20vh;
    left: 30%;
    width: 40%;
    z-index: 100;
    border-radius: 12px;
    border: 4px solid #0d5379;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: white;
}

section {
    padding: 1rem;
}

.dialog-enter-from,
.dialog-leave-to {
    opacity: 0;
    transform: scale(0.8);
}

.dialog-enter-to,
.dialog-leave-from {
    opacity: 1;
    transform: scale(1);
}

.dialog-enter-active {
    transition: all 0.3s ease-out;
}

.dialog-leave-active {
    transition: all 0.3s ease-in;
}

@media all and (max-width: 1848px) {
    dialog {
        width: 60rem;
        left: calc(50% - 30rem);
    }
}

@media all and (max-height: 1080px) {
    dialog {
        left: calc(50% - 28rem);
        width: 55rem;
    }
}

@media all and (max-width: 926px) {
    dialog {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}
@media all and (max-width: 926px) and (max-height: 1000px) {
    dialog {
        max-height: 300px;
    }
}

@media all and (max-width: 768px) {
    dialog {
        left: calc(50% - 10rem);
        width: 20rem;
    }
}
</style>
