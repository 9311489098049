import { createApp } from 'vue';

import App from './App.vue';
import router from './router.js';
import store from './store/index.js';

import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

import TheHeader from './components/layout/TheHeader.vue';
import TheFooter from './components/layout/TheFooter.vue';

import BaseButton from './components/ui/BaseButton.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseTimerDialog from './components/ui/BaseTimerDialog.vue';

import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE;

loadFonts();

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);

app.component('the-header', TheHeader);
app.component('the-footer', TheFooter);
app.component('base-button', BaseButton);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);
app.component('base-timer-dialog', BaseTimerDialog);

app.mount('#app');
