<template>
    <main>
        <base-dialog
            :show="!!isError"
            title="An error occured"
            @close="handleError"
        >
            <p>{{ errorMessage }}</p>
        </base-dialog>

        <base-dialog :show="isLoading" fixed title="Opening door...">
            <base-spinner></base-spinner>
        </base-dialog>

        <base-dialog
            :show="doorInProgress"
            title="Door open"
            @close="closeDoor"
        >
            <p id="timerText">Door is now open</p>
        </base-dialog>

        <base-timer-dialog :show="doorOpen">
            <p id="timerText">Door now open for</p>
            <p id="timer">{{ showTimer }} seconds</p>
        </base-timer-dialog>

        <section>
            <div>
                <div v-html="text"></div>
                <div id="box1">
                    <div id="box2">
                        <div id="acceptTermsConditionsDiv">
                            <input
                                type="checkbox"
                                id="acceptTermsConditions"
                                v-model="termsAccepted"
                            />
                            <label
                                for="acceptTermsConditions"
                                id="acceptTermsConditionsLabel"
                            >
                                I confirm that I read and understand the terms
                                and conditions
                            </label>
                        </div>
                        <div id="buttonDiv">
                            <button
                                @click="onButtonClick"
                                :disabled="!termsAccepted"
                            >
                                OPEN
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    data() {
        return {
            text: localStorage.text,
            doorOpen: false,
            termsAccepted: false,
            showTimer: null,
            doorInProgress: false,
            autologout: false,
            errorMessage: null,
            isError: false,
            isLoading: false,
        };
    },
    computed: {
        timer() {
            const timerRes = [];
            timerRes["message"] = this.$store.state.message;
            timerRes["timer"] = this.$store.state.time;
            return timerRes;
        },
    },
    methods: {
        requestOpenDoor() {
            if (this.termsAccepted && !this.isLoading) {
                this.isLoading = true;

                //send open door request
                return this.$store
                    .dispatch("requestOpenDoor", {
                        jwt: localStorage.userId,
                    })
                    .then(() => {
                        if (this.timer["message"] === "inprogress") {
                            this.doorInProgress = true;
                        } else {
                            this.openDoor(this.timer["timer"]);
                        }
                    })
                    .catch((error) => {
                        this.errorMessage = error;
                        this.autologout = true;
                        return Promise.reject(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        openDoor(currentTimer) {
            document
                .getElementById(this.$root.$el.id)
                .scrollIntoView({ behavior: "smooth" });
            document.documentElement.style.overflow = "hidden";

            this.doorOpen = true;
            this.showTimer = currentTimer;

            if (currentTimer > -1) {
                setTimeout(() => {
                    currentTimer -= 1;
                    this.showTimer = currentTimer;
                    this.openDoor(currentTimer);
                }, 1000);
            } else {
                this.closeDoor();
            }
        },
        closeDoor() {
            this.termsAccepted = false;
            this.doorOpen = false;
            this.doorInProgress = false;
            document.documentElement.style.overflow = "auto";
        },
        handleError() {
            this.termsAccepted = false;
            this.errorMessage = null;
            document.documentElement.style.overflow = "auto";

            if (this.autologout) {
                this.$store.dispatch("logout");
                this.$router.replace("/auth");
            }
        },
        onButtonClick() {
            this.requestOpenDoor();
        },
    },
    watch: {
        errorMessage() {
            if (this.errorMessage === null) {
                this.isError = false;
            } else {
                this.isError = true;
            }
        },
    },
};
</script>

<style scoped>
main,
.box1 {
    display: flex;
    justify-content: center;
}
section {
    width: 100%;
    max-width: 1280px;
    margin: 0 10vw;
}

p {
    margin-bottom: 20px;
    text-align: justify;
}

.box2 {
    width: 100%;
    max-width: 621px;
}

#acceptTermsConditionsDiv {
    display: flex;
    margin-top: 40px;
    height: 100px;
}

#acceptTermsConditionsLabel {
    font: normal 21px "Titillium Web Light";
    color: #0d202c;
    padding-left: 40px;
    text-align: left;
    cursor: pointer;
}

#acceptTermsConditions {
    width: 45px;
    height: 45px;
    background: #0a1821 0% 0% no-repeat padding-box;
    cursor: pointer;
}

#buttonDiv {
    max-width: 850px;
    margin: auto;
}

button {
    padding: 5px;
    width: 100%;
    background: #2c97d3 0% 0% no-repeat padding-box;
    border-radius: 15px;
    font: normal 600 3rem "Titillium Web SemiBold";
    color: #ffffff;
}

button:disabled {
    background: grey 0% 0% no-repeat padding-box;
}

#timerText {
    text-align: center;
    font: normal 300 60px "Titillium Web Light";
    color: #0d5379;
    font-size: 60px;
    padding-bottom: 100px;
    padding-top: 100px;
}
#timer {
    text-align: center;
    font: normal bold 100px "Titillium Web SemiBold";
    color: #2c97d3;
    padding-bottom: 100px;
}

@media all and (max-width: 1080px) {
    #timerText {
        padding-bottom: 75px;
        padding-top: 50px;
        margin: 0;
    }
    #timer {
        font-size: 100px;
        padding-bottom: 0;
    }
}

@media all and (max-width: 926px) {
    #timerText {
        padding-bottom: 50px;
        padding-top: 20px;
        font-size: 30px;
    }
    #timer {
        font-size: 50px;
    }
}

@media all and (max-width: 480px) {
    #acceptTermsConditionsLabel {
        margin-left: 20px;
        font-size: 1rem;
    }
    #timerText {
        padding-bottom: 50px;
        padding-top: 20px;
        font-size: 30px;
    }
    #timer {
        font-size: 50px;
    }
}
</style>
