import { createRouter, createWebHistory } from "vue-router";

import UserAuth from "./pages/UserAuth.vue";
import TermsConditions from "./pages/TermsConditions.vue";

import store from "./store/index.js";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/", redirect: "/auth" },
        { path: "/auth", component: UserAuth },
        {
            path: "/terms",
            component: TermsConditions,
            meta: { requiresAuth: true },
        },
    ],
});

// check if user is logged in and allow to go to next page

router.beforeEach(function (to, _, next) {
    if (to.meta.requiresAuth && !store.getters.userId) {
        next("/auth");
    } else {
        next();
    }
});

export default router;
