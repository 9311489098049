import { createStore } from "vuex";

import actions from "./actions.js";

const store = createStore({
    state() {
        return {
            userId: null,
        };
    },
    mutations: {
        setUser(state, payload) {
            state.userId = payload.userId;
            state.text = payload.text;
        },
        setTime(state, payload) {
            state.message = payload.message;
            state.time = payload.time;
        },
    },
    actions,
    getters: {
        userId(state) {
            return state.userId;
        },
        time(state) {
            return state.time;
        },
    },
});

export default store;
