<template>
    <div id="myWrap">
        <the-header></the-header>
        <router-view></router-view>
        <the-footer></the-footer>
    </div>
</template>

<script>
export default {
    watch: {
        userId(curValue) {
            if (!curValue) {
                this.$router.replace("/auth");
            }
        },
    },
    created() {
        this.$store.dispatch("tryLogin");
    },
};
</script>

<style>
@font-face {
    font-family: "Titillium Web Light";
    src: url("../src/assets/fonts/TitilliumWeb-Light.ttf");
}
@font-face {
    font-family: "Titillium Web SemiBold";
    src: url("../src/assets/fonts/TitilliumWeb-SemiBold.ttf");
}

* {
    font-family: "Titillium Web Light", sans-serif;
    letter-spacing: 0px;
}

#myWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.textCenter {
    text-align: center;
}
</style>
