<template>
    <button v-if="!link" :class="mode">
        <slot></slot>
    </button>
    <router-link v-else :to="to" :class="mode">
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            required: false,
            default: null,
        },
        link: {
            type: Boolean,
            required: false,
            default: false,
        },
        to: {
            type: String,
            required: false,
            default: "/",
        },
    },
};
</script>

<style scoped>
button,
a {
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    font-family: "Titillium Web Light";
    font-size: 18px;
    border: 1px solid #2c97d3;
    color: #2c97d3;
    cursor: pointer;
    border-radius: 30px;
    margin-right: 0.5rem;
    display: inline-block;
}

a:hover,
a:active,
button:hover,
button:active {
    background-color: #2c97d3;
    color: white;
}

.flat {
    background-color: transparent;
    color: #3a0061;
    border: none;
}

.outline {
    background-color: transparent;
    border-color: #270041;
    color: #270041;
}

.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
    background-color: #edd2ff;
}
</style>
