<template>
    <footer>
        <p class="textCenter">&copy; Boulder Klub Mamer - powered by ION</p>
    </footer>
</template>

<style scoped>
footer {
    margin: 20px 0;
}
p {
    width: 80%;
    margin: auto;
}
</style>
