import axios from "axios";

export default {
    login(context, payload) {
        // send data to login
        return axios
            .post(
                "/bkm/login",
                {
                    username: payload.username,
                    password: payload.password,
                },
                {
                    signal: newAbortSignal(5000),
                }
            )
            .then((data) => {
                console.log(data);
                const responseData = data.data;
                const userId = responseData.jwt;
                const text = responseData.text;

                // check if response is not ok
                if (responseData.message != "success") {
                    this.logout;
                    throw responseData.message;
                }

                localStorage.setItem("userId", userId);
                localStorage.setItem("text", text);

                // auth user
                context.commit("setUser", {
                    userId: userId,
                    text: text,
                });
            })
            .catch((error) => {
                if (error.message === "canceled") {
                    throw new Error("Request got a timeout. Please try again.");
                }
                throw new Error(error);
            });
    },
    tryLogin(context) {
        const userId = localStorage.getItem("userId");

        if (userId) {
            context.commit("setUser", {
                userId: userId,
            });
        }
    },
    logout(context) {
        localStorage.removeItem("userId");

        // loggout user
        context.commit("setUser", {
            userId: null,
        });
    },

    requestOpenDoor(context, payload) {
        // send data to login
        return axios
            .post(
                "/bkm/open",
                {
                    jwt: payload.jwt,
                },
                {
                    signal: newAbortSignal(5000),
                }
            )
            .then((data) => {
                const responseData = data.data;

                context.commit("setTime", {
                    message: responseData.message,
                    time: responseData.time,
                });
            })
            .catch((error) => {
                if (error.message === "canceled") {
                    throw new Error("Request got a timeout. Please try again.");
                }
                throw new Error(error);
            });
    },
};

function newAbortSignal(timeoutMs) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);

    return abortController.signal;
}
