<template>
    <main>
        <base-dialog
            :show="!!isError"
            title="An error occured"
            @close="handleError"
        >
            <p>{{ errorMessage }}</p>
        </base-dialog>
        <base-dialog :show="isLoading" fixed title="Autenticating...">
            <base-spinner></base-spinner>
        </base-dialog>
        <section id="sectionForm">
            <div id="formDiv">
                <form id="form" @submit.prevent="submitForm">
                    <div id="usernameDiv">
                        <label for="username">Email/Username</label>
                        <input
                            type="text"
                            id="username"
                            v-model.trim="username"
                            name="username"
                        />
                    </div>
                    <div id="passwordDiv">
                        <label for="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            v-model.trim="password"
                            name="password"
                        />
                    </div>
                    <div id="buttonDiv">
                        <button>Login</button>
                    </div>
                </form>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    data() {
        return {
            username: "",
            password: "",
            errorMessage: null,
            isError: false,
            isLoading: false,
        };
    },
    methods: {
        submitForm() {
            if (this.isLoading) return;

            this.isLoading = true;

            //TODO: check what to verify to validate form
            if (this.username === "" || this.password.length < 6) {
                this.isLoading = false;
                this.errorMessage =
                    "Please enter a email/username and password (must be at least 6 characters long)";
                return Promise.reject(new Error(this.errorMessage));
            }

            return this.$store
                .dispatch("login", {
                    username: this.username,
                    password: this.password,
                })
                .then(() => {
                    this.$router.replace("/terms");
                })
                .catch((error) => {
                    this.errorMessage = error;
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        handleError() {
            this.errorMessage = null;
        },
    },
    watch: {
        errorMessage() {
            if (this.errorMessage === null) {
                this.isError = false;
            } else {
                this.isError = true;
                this.password = "";
            }
        },
    },
};
</script>

<style scoped>
#sectionForm {
    display: flex;
}

#formDiv {
    margin: auto;
}

#form {
    width: 650px;
}

input[type] {
    height: 42px;
    width: 100%;

    font-size: 1.5rem;
    padding: 0 8px;
    margin-bottom: 15px;

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0d202c;
    border-radius: 8px;
}

label {
    font: normal 2rem "Titillium Web Light";
    color: #0d202c;
}

#buttonDiv {
    text-align: right;
    font: normal 600 3rem "Titillium Web SemiBold";
    color: #2c97d3;
    margin-right: 10px;
}

@media all and (max-width: 1080px) {
    #formDiv {
        width: 80%;
        max-width: 650px;
    }
    #form {
        width: auto;
        margin: 0 10px;
    }
}
</style>
